@font-face {
    font-family: 'jinling';
    src: url('./font/jinling.TTF');
  }
  
/* TODO 自适应，媒体查询没起作用 */
  
html, body {
background: whitesmoke;
color: darkslategrey;
-webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
/* font-size: 16px; */
font-family: jinling;
}
body {
height: 100vh;
margin: 0;
padding: 0;
border: none;
overflow: hidden;
}